import * as Types from '../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GroupUserSelectInputQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GroupUserSelectInputQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string }> };


export const GroupUserSelectInputDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GroupUserSelectInput"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGroupUserSelectInputQuery__
 *
 * To run a query within a React component, call `useGroupUserSelectInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupUserSelectInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupUserSelectInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupUserSelectInputQuery(baseOptions?: Apollo.QueryHookOptions<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>(GroupUserSelectInputDocument, options);
      }
export function useGroupUserSelectInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>(GroupUserSelectInputDocument, options);
        }
export function useGroupUserSelectInputSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>(GroupUserSelectInputDocument, options);
        }
export type GroupUserSelectInputQueryHookResult = ReturnType<typeof useGroupUserSelectInputQuery>;
export type GroupUserSelectInputLazyQueryHookResult = ReturnType<typeof useGroupUserSelectInputLazyQuery>;
export type GroupUserSelectInputSuspenseQueryHookResult = ReturnType<typeof useGroupUserSelectInputSuspenseQuery>;
export type GroupUserSelectInputQueryResult = Apollo.QueryResult<GroupUserSelectInputQuery, GroupUserSelectInputQueryVariables>;